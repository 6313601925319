import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import { Stage, Container, Teaser, TeaserContainer } from '@ccg/middleeast';

import PageTemplate from '../templates/page';
import { removeSpecialCharacters } from '../../../helper';

import relatedCasesQuery from '../../../graphql/queries/relatedCases.graphql';
import errorQuery from '../../../graphql/queries/error.graphql';

import { withTranslation } from '../../../i18n';

const Error = ({ t, statusCode, site }) => {
  const { data } = useQuery(errorQuery, {
    variables: {
      site
    }
  });

  const { data: relatedData } = useQuery(relatedCasesQuery, {
    variables: {
      site,
      limit: 4
    }
  });

  return data ? (
    <PageTemplate
      navigation={data.navigation}
      seo={data.entry}
      footer={data.footer}
      social={data.social}
      seoDefaults={data.seoDefaults}
      cookieLayer={data.cookieLayer}
    >
      <Stage
        image={
          data.entry.image &&
          data.entry.image.length > 0 && {
            ...data.entry.image[0],
            srcset: {
              '1440w': data.entry.image[0].width1440,
              '960w': data.entry.image[0].width960,
              '720w': data.entry.image[0].width720,
              '375w': data.entry.image[0].width375
            }
          }
        }
        video={data.entry.video && data.entry.video.length > 0 && data.entry.video[0]}
        loop={data.entry.loop}
        headline={`${statusCode || 500} ${data.entry.headline || 'Sorry, something went wrong'}`}
        subline={data.entry.subline}
        title={removeSpecialCharacters(data.entry.title)}
      />

      {relatedData && relatedData.relatedEntries && relatedData.relatedEntries.length > 0 && (
        <Container variant="default" headline={t('teaser.relatedContent')}>
          <TeaserContainer>
            {relatedData.relatedEntries.map((item, index) => (
              <Teaser
                key={item.id}
                index={index}
                topic={item.client && item.client.length > 0 && item.client[0].title}
                headline={item.title}
                image={item.image.length > 0 && item.image[0]}
                video={item.video.length > 0 && item.video[0]}
                link={{
                  href: { pathname: '/case', query: { slug: item.slug } },
                  asPath: `/${item.uri}`
                }}
              />
            ))}
          </TeaserContainer>
        </Container>
      )}
    </PageTemplate>
  ) : null;
};

Error.propTypes = {
  t: PropTypes.func.isRequired,
  statusCode: PropTypes.string,
  site: PropTypes.string.isRequired
};

Error.defaultProps = { statusCode: 500 };

export default withTranslation('common')(Error);
